function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { RESET_PASSWORD_ERROR, RESET_PASSWORD_STARTED, RESET_PASSWORD_SUCCESS } from 'swag-client-common/redux/user/user-additional.actions';
import { STATE_ERROR, STATE_IDLE, STATE_LOADING, STATE_SUCCESS } from '../redux.constants';
import { LOGIN_ERROR, LOGIN_STARTED, LOGIN_SUCCESS, SET_HASH, REDIRECT_BACK_TO_API_ERROR, GET_APPLICATION_NAME_SUCCESS } from './login.actions';
const initialState = {
  hash: null,
  applicationName: '',
  provider: null,
  isLoggedIn: false,
  userId: null,
  state: STATE_IDLE,
  error: '',
  errorCode: null,
  inventories: [],
  passwordResetErrorMessage: '',
  passwordResetState: STATE_IDLE
};
export function loginReducer(state = initialState, action) {
  switch (action.type) {
    case REDIRECT_BACK_TO_API_ERROR:
      {
        return _objectSpread({}, initialState, {
          hash: state.hash,
          applicationName: state.applicationName
        });
      }
    case SET_HASH:
      {
        return _objectSpread({}, state, {
          hash: action.payload
        });
      }
    case GET_APPLICATION_NAME_SUCCESS:
      {
        return _objectSpread({}, state, {
          applicationName: action.body.applicationName,
          provider: action.body.provider
        });
      }
    case LOGIN_SUCCESS:
      {
        const {
          profile,
          inventories
        } = action.body;
        return _objectSpread({}, state, {
          inventories: (inventories !== null && inventories !== void 0 ? inventories : []).map(({
            _id,
            name
          }) => ({
            _id,
            name
          })),
          isLoggedIn: true,
          state: STATE_SUCCESS,
          error: '',
          errorCode: null,
          userId: profile._id
        });
      }
    case LOGIN_ERROR:
      {
        return _objectSpread({}, state, {
          state: STATE_ERROR,
          error: action.error.message,
          errorCode: action.error.code
        });
      }
    case LOGIN_STARTED:
      {
        return _objectSpread({}, state, {
          state: STATE_LOADING,
          error: '',
          errorCode: null
        });
      }
    case RESET_PASSWORD_STARTED:
      return _objectSpread({}, state, {
        passwordResetState: STATE_LOADING,
        passwordResetErrorMessage: '',
        errorCode: null
      });
    case RESET_PASSWORD_SUCCESS:
      return _objectSpread({}, state, {
        passwordResetState: STATE_SUCCESS,
        passwordResetErrorMessage: '',
        errorCode: null
      });
    case RESET_PASSWORD_ERROR:
      return _objectSpread({}, state, {
        passwordResetState: STATE_ERROR,
        passwordResetErrorMessage: action.error.message,
        errorCode: null
      });
    default:
      {
        return state;
      }
  }
}