import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import ForgotPasswordPopup from 'swag-client-common/components/popups/forgot-password-popup';
import { hidePopup } from 'swag-client-common/redux/popup-manager/popup-manager.actions';
import { isForgotPasswordPopupShownSelector } from 'swag-client-common/redux/popup-manager/popup-manager.selector';
import { checkIsRestrictedCuratedBrandStore } from 'swag-common/utils/curated-brand-store.utils';
import { resetPassword } from 'swag-client-common/redux/user/user-additional.actions';
import { APP_ROUTES } from 'swag-client-common/constants/app-routes';
import { getStoreSettings } from 'swag-client-common/utils/get-store-settings';
import { isPasswordResetStateErrorSelector, isPasswordResetStateLoadingSelector, isPasswordResetStateSuccessSelector, passwordResetErrorMessageSelector } from '../../redux/login/login.selector';
const showCloseButtonSelector = () => {
  const {
    curatedBrandStore
  } = getStoreSettings();
  const {
    restrictionLevel
  } = curatedBrandStore !== null && curatedBrandStore !== void 0 ? curatedBrandStore : {};
  return !checkIsRestrictedCuratedBrandStore(restrictionLevel);
};
const mapStateToProps = createStructuredSelector({
  isError: isPasswordResetStateErrorSelector,
  isSuccess: isPasswordResetStateSuccessSelector,
  isLoading: isPasswordResetStateLoadingSelector,
  errorMessage: passwordResetErrorMessageSelector,
  isOpen: isForgotPasswordPopupShownSelector,
  showCloseButton: showCloseButtonSelector
});
const mapDispatchToProps = dispatch => ({
  onSubmit: values => {
    dispatch(resetPassword(values));
  },
  onShowSignUp: () => {
    window.location.href = `${APP_ROUTES.HOME}?signup=true`;
  },
  onClose: () => {
    const {
      curatedBrandStore
    } = getStoreSettings();
    const {
      restrictionLevel
    } = curatedBrandStore !== null && curatedBrandStore !== void 0 ? curatedBrandStore : {};
    if (!checkIsRestrictedCuratedBrandStore(restrictionLevel)) {
      dispatch(hidePopup());
    }
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordPopup);