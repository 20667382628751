import { getApplicationName, setHash } from '../redux/login/login.actions';
export const onLoginEnter = (store, dispatch) => async (nextState, replace, next) => {
  if (nextState.location.query.hash) {
    dispatch(setHash(nextState.location.query.hash));
  }
  if (nextState.location.query.app) {
    await dispatch(getApplicationName({
      userId: nextState.location.query.app
    }));
  }
  next();
};