import { createApi } from 'swag-client-common/libs/rapi';
const LoginApi = createApi({
  login: {
    url: '/api/v1/auth/api-login',
    method: 'post'
  },
  getAuthorizeUrl: {
    url: '/api/v1/auth/get-authorize-url',
    method: 'post'
  },
  getInventories: {
    url: '/custom-store-api/v1/inventory/api-list',
    method: 'get'
  },
  getAppName: {
    url: '/api/v1/user/application/:userId',
    method: 'get'
  }
});
export { LoginApi };