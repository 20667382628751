import getMuiTheme from 'material-ui/styles/getMuiTheme';
import { createMuiTheme } from '@material-ui/core/styles';
import { muiTheme } from 'swag-client-common/styles/mui/mui-theme';
import { variables } from 'swag-client-common/styles/variables-js';
const themeOptionsV0 = {
  fontFamily: variables.fontFamilyRegular,
  textField: {
    floatingLabelColor: '#37474f'
  },
  toggle: {
    trackOnColor: '#84D8FF'
  }
};
export const themeV0 = getMuiTheme(themeOptionsV0);
export const theme = createMuiTheme(muiTheme);