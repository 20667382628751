import { createSelector } from 'reselect';
import { INTEGRATED_APP_NAMES } from 'swag-common/constants/public-api';
import { STATE_ERROR, STATE_LOADING, STATE_SUCCESS } from '../redux.constants';
export const rootSelector = state => state.login;
export const isStateErrorSelector = createSelector(rootSelector, state => state.state === STATE_ERROR);
export const errorMessageSelector = createSelector(rootSelector, state => state.error);
export const modeSelector = createSelector(rootSelector, state => state.mode);
export const applicationNameSelector = createSelector(rootSelector, state => state.applicationName);
export const isStateLoadingSelector = createSelector(rootSelector, state => state.state === STATE_LOADING);
export const isNotVerifiedEmailSelector = createSelector(rootSelector, state => state.errorCode === 110);
export const isAccountBannedByIpSelector = createSelector(rootSelector, state => state.errorCode === 112);
export const passwordResetStateSelector = createSelector(rootSelector, state => state.passwordResetState);
export const isPasswordResetStateErrorSelector = createSelector(passwordResetStateSelector, passwordResetState => passwordResetState === STATE_ERROR);
export const isPasswordResetStateSuccessSelector = createSelector(passwordResetStateSelector, passwordResetState => passwordResetState === STATE_SUCCESS);
export const isPasswordResetStateLoadingSelector = createSelector(passwordResetStateSelector, passwordResetState => passwordResetState === STATE_LOADING);
export const passwordResetErrorMessageSelector = createSelector(rootSelector, state => state.passwordResetErrorMessage);
export const inventoriesSelector = createSelector(rootSelector, state => state.inventories);
export const isShopifyAppSelector = createSelector(rootSelector, state => state.provider === INTEGRATED_APP_NAMES.SHOPIFY);