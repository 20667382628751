import { createAsyncAction, errorAction } from 'swag-client-common/libs/redux-helpers';
import { showNotification } from 'swag-client-common/redux/notification/notification.actions';
import { INTEGRATED_APP_NAMES } from 'swag-common/constants/public-api';
import { LoginApi } from '../../api/login.api';
const PREFIX = 'SWAG_EXTERNAL_';
export const SET_HASH = `${PREFIX}SET_HASH`;
export const setHash = hash => ({
  type: SET_HASH,
  payload: hash
});
export const LOGIN_STARTED = `${PREFIX}LOGIN_STARTED`;
export const LOGIN_ERROR = `${PREFIX}LOGIN_ERROR`;
export const LOGIN_SUCCESS = `${PREFIX}LOGIN_SUCCESS`;
export const login = createAsyncAction({
  preFn: dispatch => {
    dispatch({
      type: LOGIN_STARTED
    });
  },
  transformData: ({
    login,
    password
  }, getState) => {
    const {
      provider
    } = getState().login;
    return {
      login,
      password,
      provider
    };
  },
  fn: LoginApi.login,
  success: body => ({
    type: LOGIN_SUCCESS,
    body
  }),
  error: errorAction(LOGIN_ERROR),
  postSuccess: (dispatch, getState) => {
    const {
      inventories
    } = getState().login;
    if (inventories.length === 1) {
      dispatch(redirectBackToApi({
        inventories: [inventories[0]._id],
        isAllInventoriesAllowed: false
      }));
    }
  }
});
export const GET_APPLICATION_NAME_ERROR = `${PREFIX}GET_APPLICATION_NAME_ERROR`;
export const GET_APPLICATION_NAME_SUCCESS = `${PREFIX}GET_APPLICATION_NAME_SUCCESS`;
export const getApplicationName = createAsyncAction({
  fn: LoginApi.getAppName,
  success: body => ({
    type: GET_APPLICATION_NAME_SUCCESS,
    body
  }),
  error: errorAction(GET_APPLICATION_NAME_ERROR)
});
export const REDIRECT_BACK_TO_API_SUCCESS = `${PREFIX}REDIRECT_BACK_TO_API_SUCCESS`;
export const REDIRECT_BACK_TO_API_ERROR = `${PREFIX}REDIRECT_BACK_TO_API_ERROR`;
export const redirectBackToApi = createAsyncAction({
  transformData: ({
    inventories,
    isAllInventoriesAllowed
  }, getState) => {
    const {
      hash,
      userId,
      applicationName,
      provider
    } = getState().login;
    return {
      state: hash,
      userId,
      inventories,
      isAllInventoriesAllowed,
      applicationName,
      provider
    };
  },
  fn: LoginApi.getAuthorizeUrl,
  success: () => ({
    type: REDIRECT_BACK_TO_API_SUCCESS
  }),
  error: errorAction(REDIRECT_BACK_TO_API_ERROR),
  postError: (dispatch, getState, error) => {
    dispatch(showNotification({
      text: error.message
    }));
  },
  postSuccess: (dispatch, getState, transformedData, {
    redirectUrl
  }) => {
    if (redirectUrl) {
      if (transformedData.provider === INTEGRATED_APP_NAMES.SHOPIFY && top) {
        top.window.location.href = redirectUrl;
      } else {
        window.location.href = redirectUrl;
      }
    }
  }
});