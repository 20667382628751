import _reducer from "redux-form/es/reducer";
import thunk from 'redux-thunk';
import { createStore, applyMiddleware, combineReducers, compose } from 'redux';
import { syncHistory, routeReducer } from 'redux-simple-router';
import { browserHistory } from 'react-router';
import notification from 'swag-client-common/redux/notification/notification.reducer';
import popupManager from 'swag-client-common/redux/popup-manager/popup-manager.reducer';
import { loginReducer } from './login/login.reducer';
const reducers = combineReducers({
  routing: routeReducer,
  form: _reducer,
  login: loginReducer,
  notification,
  popupManager
});
const middleware = [thunk, syncHistory(browserHistory)];
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export const loginStore = createStore(reducers, {}, composeEnhancers(applyMiddleware(...middleware)));