import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { showPopup } from 'swag-client-common/redux/popup-manager/popup-manager.actions';
import { resendVerificationEmail } from 'swag-client-common/redux/user/user-additional.actions';
import { LoginForm } from 'swag-client-common/components/login-form';
import { APP_ROUTES } from 'swag-client-common/constants/app-routes';
import { FORGOT_PASSWORD_POPUP } from 'swag-client-common/constants';
import { STATE_LOADING } from '../../redux/redux.constants';
import { login } from '../../redux/login/login.actions';
import { applicationNameSelector, errorMessageSelector, isNotVerifiedEmailSelector, isAccountBannedByIpSelector, isStateErrorSelector, isStateLoadingSelector, modeSelector, passwordResetErrorMessageSelector, passwordResetStateSelector } from '../../redux/login/login.selector';
const loginFormSelector = createSelector(errorMessageSelector, modeSelector, passwordResetStateSelector, passwordResetErrorMessageSelector, isNotVerifiedEmailSelector, isAccountBannedByIpSelector, isStateErrorSelector, isStateLoadingSelector, applicationNameSelector, (errorMessage, mode, passwordResetState, passwordResetErrorMessage, notVerifiedEmail, isAccountBannedByIp, isError, isLoading, applicationName) => {
  return {
    errorMessage,
    mode,
    passwordResetState,
    passwordResetErrorMessage,
    notVerifiedEmail,
    isAccountBannedByIp,
    isError,
    isLoading,
    title: 'Login',
    isLabelFixed: true,
    isSignUpAllowed: true,
    additionalText: `The application "${applicationName}" would like to get access to your Swag.com account information. Sign in using your Swag.com credentials to proceed.`
  };
});
export const loginFormDispatcher = (dispatch, props) => ({
  onSubmit: values => {
    const {
      state
    } = props;
    if (state !== STATE_LOADING) {
      dispatch(login(values));
    }
  },
  onPasswordForgot: () => {
    dispatch(showPopup({
      popup: FORGOT_PASSWORD_POPUP
    }));
  },
  onResendVerificationEmail: () => {
    dispatch(resendVerificationEmail());
  },
  onShowSignUp: () => {
    window.location.href = `${APP_ROUTES.HOME}?signup=true`;
  }
});
export default connect(loginFormSelector, loginFormDispatcher)(LoginForm);