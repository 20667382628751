import { variables as variablesFromHTML } from 'swag-client-common/styles/css-variables-helper';
import { variables } from 'swag-client-common/styles/variables-js';
export const getMuiThemeBase = () => ({
  typography: {
    fontFamily: variables.fontFamilyRegular
  },
  palette: {
    primary: {
      main: variablesFromHTML.primary
    },
    secondary: {
      main: variablesFromHTML.secondary,
      contrastText: '#fff'
    }
  },
  overrides: {
    MuiTab: {
      root: {
        textTransform: 'none',
        padding: '30px 10px'
      }
    },
    MuiTabs: {
      indicator: {
        backgroundColor: variablesFromHTML.primary
      },
      root: {
        boxShadow: 'inset 0 -2px 0 rgba(177, 177, 177, 0.2)'
      }
    },
    MuiTouchRipple: {
      ripple: {
        color: '#fff'
      }
    },
    MuiPopover: {
      root: {
        zIndex: '1600 !important'
      }
    },
    MuiSelect: {
      root: {
        zIndex: '1600 !important'
      },
      select: {
        '&:focus': {
          background: 'transparent !important'
        }
      }
    }
  }
});