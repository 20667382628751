import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { redirectBackToApi } from '../../redux/login/login.actions';
import { isShopifyAppSelector, inventoriesSelector } from '../../redux/login/login.selector';
import { InventorySelect as InventorySelectComponent } from './inventory';
const mapStateToProps = createStructuredSelector({
  inventories: inventoriesSelector,
  isShopifyApp: isShopifyAppSelector
});
export const mapDispatchToProps = dispatch => ({
  onSubmit: (inventories, isAllInventoriesAllowed) => {
    dispatch(redirectBackToApi({
      inventories,
      isAllInventoriesAllowed
    }));
  }
});
export const InventorySelect = connect(mapStateToProps, mapDispatchToProps)(InventorySelectComponent);