import { connect } from 'react-redux';
import { APP_ROUTES } from 'swag-client-common/constants/app-routes';
import { INTEGRATED_APP_NAMES } from 'swag-common/constants/public-api';
import { InfoMessagePage as InfoMessagePageComponent } from './info-message-page';
export const mapDispatchToProps = () => ({
  onClick: applicationName => {
    if (String(applicationName).toUpperCase() === INTEGRATED_APP_NAMES.SHOPIFY) {
      window.open(APP_ROUTES.ALL_SWAG, '_blank');
      return;
    }
    window.location.href = APP_ROUTES.ALL_SWAG;
  }
});
export const InfoMessagePage = connect(null, mapDispatchToProps)(InfoMessagePageComponent);