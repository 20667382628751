import { connect } from 'react-redux';
import { sessionPing } from 'swag-client-common/redux/user/user-additional.actions';
import { isGiveawayFeesPathSelector, isHomePageSelector } from '../../../customer/redux/common/common.selectors';
import RootComponent from './root';
const mapStateToProps = state => {
  var _state$user;
  return {
    isLoggedIn: Boolean((_state$user = state.user) === null || _state$user === void 0 ? void 0 : _state$user.isLoggedIn),
    isGiveawayFeesPage: isGiveawayFeesPathSelector(state),
    isHomePage: isHomePageSelector(state)
  };
};
const mapDispatchToProps = dispatch => ({
  onWindowFocus: () => {
    dispatch(sessionPing());
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(RootComponent);